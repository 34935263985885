import { StepSyncProgress, IStepSyncProgressOptions, IStepSyncProgressJSON } from 'o365.pwa.modules.client.steps.StepSyncProgress.ts';

export interface IStoredProcedureProgressJSON extends IStepSyncProgressJSON {
    procedureHasStarted: boolean;
    procedureHasErrors: boolean;
    procedureHasCompleted: boolean;
}

export interface IStoredProcedureProgressOptions extends IStepSyncProgressOptions {
    procedureHasStarted?: boolean;
    procedureHasErrors?: boolean;
    procedureHasCompleted?: boolean;
}

export class StoredProcedureProgress extends StepSyncProgress {
    public procedureHasStarted: boolean = false;
    public procedureHasErrors: boolean = false;
    public procedureHasCompleted: boolean = false;


    get progressStatusPending(): number {
        return this.procedureHasStarted ? 100 : 0;
    }

    get progressStatusSuccess(): number {
        return this.procedureHasCompleted ? 100 : 0;
    }

    get progressStatusError(): number {
        return this.procedureHasErrors ? 100 : 0;
    }
    
    constructor(options: IStoredProcedureProgressOptions) {
        super(options);

        options.procedureHasStarted && (this.procedureHasStarted = options.procedureHasStarted);
        options.procedureHasErrors && (this.procedureHasErrors = options.procedureHasErrors);
        options.procedureHasCompleted && (this.procedureHasCompleted = options.procedureHasCompleted);
    }

    public toJSON(): IStoredProcedureProgressJSON {
        return Object.assign(super.toJSON(), this);
    }
}
